import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import {  Box } from "@mui/material";
import CustomDatagrid from "../../components/CustomDatagrid";
import { GetData } from "../../Data/GetDataHook";
import CustomModal from "../../components/CustomModal";
import AgregarServicio from "./AgregarServicio";
import { DeleteDataServicio } from "../../Data/DeleteDataHook";
import EditarServicio from "./EditarServicio";

const Servicios = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { data, loading, getData } = GetData("servicios");
  const { DeleteServicio } = DeleteDataServicio();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleDelete = (id) => {
    if (window.confirm("Esta seguro de eliminar este producto?")) {
      DeleteServicio(id);
      getData();
    }
  };
  const handleEdit = (cellValues) => {
 
    setEditData(cellValues);
    setIsEdit(true);
    setOpen(true);
  };
  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={(event) => handleEdit(cellValues)}
              sx={{ mr: 2 }}
            >
              Editar
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => handleDelete(cellValues.row.id)}
            >
              Borrar
            </CustomButton>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: 150 },
    { field: "nombre", headerName: "Nombre", width: 150 },
    { field: "descripcion", headerName: "Descripción", width: 150 },
    { field: "precio", headerName: "Precio", width: 150 },
    { field: "departamento", headerName: "Departamento", width: 150 },
  ];

  useEffect(() => {
    getData();
  }, [open]);

  return (
    <>
      <Box mb={2}>
        <CustomButton onClick={() => handleOpen()}>
          Agregar Servicio
        </CustomButton>
      </Box>
      <CustomDatagrid rows={data} columns={columns} loading={loading} />
      <CustomModal open={open} handleClose={handleClose}>
        {isEdit ? (
          <EditarServicio data={editData} handleClose={handleClose} />
        ) : (
          <AgregarServicio handleClose={handleClose} />
        )}
      </CustomModal>
    </>
  );
};

export default Servicios;
