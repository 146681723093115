import axios from "axios";
import React from "react";
import Chart from "react-apexcharts";
import { format } from "date-fns";
import { Card } from "@mui/material";

const ChartAnual = () => {
  const options2 = { style: "currency", currency: "USD" };
  const numberFormat2 = new Intl.NumberFormat("en-US", options2);
  const [finanzas, setFinanzas] = React.useState([]);
  const [data, setData] = React.useState([]);

  const [options, setOptions] = React.useState({
    noData: {
      text: "Loading...",
    },
    chart: {
      id: "basic-bar",
      background: "#ffffff",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      title: {
        text: "",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Finanzas Anuales",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#666",
        fontWeight: "regular",
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return numberFormat2.format(val);
        },
      },
    },
  });

  React.useEffect(() => {
    axios
      .get("https://doctorstotalclinic.onrender.com/api/finanzas/anuales")
      .then((res) => {
        setFinanzas(res?.data);

        setData([
          {
            name: "INGRESOS",
            data: res?.data.map((item) => item.ingresos),
          },
          {
            name: "EGRESOS",
            data: res?.data.map((item) => item.egresos),
          },
        ]);
        setOptions({
          xaxis: {
            categories: res?.data.map((finanza) =>
              format(new Date(finanza.fecha), "yyyy")
            ),
          },
        });
      });
  }, []);

  return (
    <Card sx={{ width: 500, height: 300 }}>
      <Chart
        options={options}
        series={data}
        type="bar"
        width={500}
        height={300}
      />
    </Card>
  );
};

export default ChartAnual;
