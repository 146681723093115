import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CustomButton from "../../components/CustomButton";
import CustomDatagrid from "../../components/CustomDatagrid";
import { GetData } from "../../Data/GetDataHook";
import CustomModal from "../../components/CustomModal";
import AgregarFinanza from "./AgregarFinanza";
import { DeleteDataFinanza } from "../../Data/DeleteDataHook";
import EditarFinanza from "./EditarFinanza";

const Finanzas = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { data, loading, getData } = GetData("finanzas");
  const { DeleteFinanza } = DeleteDataFinanza();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setIsEdit(false);
    setOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Esta seguro de eliminar este producto?")) {
      DeleteFinanza(id);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [open]);

  const handleEdit = (cellValues) => {
    setEditData(cellValues);
    setIsEdit(true);
    setOpen(true);
  };
  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={(event) => handleEdit(cellValues)}
              sx={{ mr: 2 }}
            >
              Editar
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => handleDelete(cellValues.row.id)}
            >
              Borrar
            </CustomButton>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", headerAlign: "center" },

    {
      field: "departamento",
      headerName: "Departamento",
      minWidth: 250,
      headerAlign: "center",
    },
    {
      field: "fecha",
      headerName: "Fecha",
      minWidth: 110,
      headerAlign: "center",
      type: "date",
      valueGetter: ({ value }) => value,
    },
    {
      field: "concepto",
      headerName: "Concepto",
      minWidth: 325,
      headerAlign: "center",
    },
    {
      field: "ingresos",
      headerName: "Ingresos",

      headerAlign: "center",
    },
    {
      field: "egresos",
      headerName: "Egresos",

      headerAlign: "center",
    },
    {
      field: "total",
      headerName: "Total",

      headerAlign: "center",
    },
    {
      field: "notas",
      headerName: "Notas",
      minWidth: 325,
      headerAlign: "center",
    },
    {
      field: "tipoPago",
      headerName: "Tipo de Pago",
      minWidth: 150,
      headerAlign: "center",
    },
    {
      field: "remitente",
      headerName: "Remitente",
      minWidth: 325,
      headerAlign: "center",
    },
    {
      field: "nombrePaciente",
      minWidth: 325,
      headerName: "Nombre del Paciente",
      headerAlign: "center",
    },
  ];

  return (
    <>
      <Box mb={2}>
        <CustomButton onClick={() => handleOpen()}>
          Agregar finanzas
        </CustomButton>
      </Box>
      <CustomDatagrid rows={data} columns={columns} loading={loading} />
      <CustomModal open={open} handleClose={handleClose}>
        {isEdit ? (
          <EditarFinanza RowData={editData} handleClose={handleClose} />
        ) : (
          <AgregarFinanza handleClose={handleClose} />
        )}
      </CustomModal>
    </>
  );
};

export default Finanzas;
