import { Typography } from "@mui/material";
import Masonry from "react-masonry-css";
import DesgloceCard from "./DesgloceCard";

const Desgloces = ({ data, dataMensual }) => {
  // console.log(dataMensual);
  return (
    <div>
      <Typography sx={{ mb: 2 }} variant="h5">
        Finanzas Generales
      </Typography>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <DesgloceCard title="Ingresos" data={data.ingresos} />
        <DesgloceCard title="Egresos" data={data.egresos} />
        <DesgloceCard title="Total" data={data.total} />
      </Masonry>
      {/* <Typography sx={{ mb: 2 }} variant="h5">
        Finanzas Mensuales
      </Typography>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <DesgloceCard title="Ingresos" data={dataMensual.ingresos} />
        <DesgloceCard title="Egresos" data={dataMensual.egresos} />
        <DesgloceCard title="Total" data={dataMensual.total} />
      </Masonry>
      <Typography sx={{ mb: 2 }} variant="h5">
        Finanzas Semanales
      </Typography>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <DesgloceCard title="Ingresos" data={data.ingresos} />
        <DesgloceCard title="Egresos" data={data.egresos} />
        <DesgloceCard title="Total" data={data.total} />
      </Masonry> */}
    </div>
  );
};

export default Desgloces;
