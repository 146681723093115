import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { Box } from "@mui/material";
import CustomDatagrid from "../../components/CustomDatagrid";
import { GetData } from "../../Data/GetDataHook";
import CustomModal from "../../components/CustomModal";
import AgregarProducto from "./AgregarProducto";
import { DeleteDataProducto } from "../../Data/DeleteDataHook";
import EditarProducto from "./EditarProducto";

const Productos = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { data, loading, getData } = GetData("productos");
  const { DeleteProducto } = DeleteDataProducto();

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleDelete = (id) => {
    if (window.confirm("Esta seguro de eliminar este producto?")) {
      DeleteProducto(id);
      getData();
    }
  };
  const handleEdit = (cellValues) => {
    setEditData(cellValues);
    setIsEdit(true);
    setOpen(true);
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => handleEdit(cellValues)}
              sx={{ mr: 2 }}
            >
              Editar
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => handleDelete(cellValues.row.id)}
            >
              Borrar
            </CustomButton>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: 150 },
    { field: "nombre", headerName: "Nombre", width: 350 },
    { field: "descripcion", headerName: "Descripción", width: 150 },
    { field: "precio", headerName: "Precio", width: 150 },
    { field: "existencia", headerName: "Existencia", width: 150 },
    { field: "departamento", headerName: "Departamento", width: 150 },
  ];

  useEffect(() => {
    getData();
  }, [open]);

  return (
    <>
      <Box mb={2}>
        <CustomButton onClick={() => handleOpen()}>
          Agregar Producto
        </CustomButton>
      </Box>
      <CustomDatagrid rows={data} columns={columns} loading={loading} />
      <CustomModal open={open} handleClose={handleClose}>
        {isEdit ? (
          <EditarProducto data={editData} handleClose={handleClose} />
        ) : (
          <AgregarProducto handleClose={handleClose} />
        )}
      </CustomModal>
    </>
  );
};

export default Productos;
