import Inventory2Icon from "@mui/icons-material/Inventory2";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DescriptionIcon from "@mui/icons-material/Description";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
export const menuItems = [
  {
    titulo: "DashBoard",
    icono: <DashboardIcon color="primary" />,
    path: "/",
  },
  {
    titulo: "Productos",
    icono: <Inventory2Icon color="primary" />,
    path: "/productos",
  },
  {
    titulo: "Servicios",
    icono: <MedicalServicesIcon color="primary" />,
    path: "/servicios",
  },
  {
    titulo: "Finanzas",
    icono: <LocalAtmIcon color="primary" />,
    path: "/finanzas",
  },
  // {
  //   titulo: "Pacientes",
  //   icono: <PersonIcon color="primary" />,
  //   path: "/pacientes",
  // },
  // {
  //   titulo: "Usuarios",
  //   icono: <VpnKeyIcon color="primary" />,
  //   path: "/usuarios",
  // },
  // {
  //   titulo: "Notas",
  //   icono: <DescriptionIcon color="primary" />,
  //   path: "/notas",
  // },
];
