import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
const CustomModal = ({ open, handleClose, children }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "80%",
    bgcolor: "background.paper",
    p: 4,
    overflow: "auto",
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
};

export default CustomModal;
