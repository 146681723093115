import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { GetData } from "../../Data/GetDataHook";
import { PostDataFinanza } from "../../Data/PostDataHook";
import { useAuth0 } from "@auth0/auth0-react";
import { PutDataProducto } from "../../Data/PutDataHook";

const CustomTextField = ({ label, ...props }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      margin="normal"
      fullWidth
      {...props}
    />
  );
};

const AgregarFinanza = ({ handleClose }) => {
  const { user } = useAuth0();
  const { postData } = PostDataFinanza();
  const { data, getData } = GetData("getprodserv");
  const { UpdateProductoOnSell } = PutDataProducto();
  const [value, setValue] = React.useState(null);

  const Departamentos = [
    "Condesa",
    "Farmapronto",
    "NF Medica ACA",
    "Ortopedia Blanda",
    "Publicidad",
    "Rayos X",
    "Terapia Fisica",
    "Traumatologia y Ortopedia",
  ];

  //Order Departamentos by alphabetical order
  Departamentos.sort();

  const tipoPago = ["Efectivo", "Tarjeta", "Cheque", "Transferencia"];
  const [finanza, setFinanza] = useState({
    departamento: "",
    fecha: "",
    concepto: "",
    ingresos: 0,
    egresos: 0,
    total: 0,
    notas: "",
    tipoPago: "",
    remitente: user.name,
    nombrePaciente: "",
  });

  const handleChange = (campo, value) => {
    setFinanza({
      ...finanza,
      [campo]: value,
    });
  };

  const handleSubmit = () => {
    let total = finanza.ingresos - finanza.egresos;
    setFinanza({
      ...finanza,
      ["total"]: total,
    });
    postData(finanza);
    UpdateProductoOnSell(finanza.concepto, finanza);
    alert("Finanza Agregada");
    handleClose();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 5 }}>
        Agregar Finanza
      </Typography>
      <InputLabel id="departamentos">Selecciona un departamento</InputLabel>
      <Select
        labelId="departamentos"
        variant="outlined"
        value={finanza.departamento}
        fullWidth
        onChange={({ target }) => handleChange("departamento", target.value)}
        sx={{ marginBottom: 2 }}
      >
        {Departamentos.map((departamento) => (
          <MenuItem key={departamento} value={departamento}>
            {departamento}
          </MenuItem>
        ))}
      </Select>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Fecha"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            handleChange("fecha", newValue);
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
      <InputLabel id="ProdServ" sx={{ marginTop: 2 }}>
        Selecciona un finanza o servicio
      </InputLabel>
      <Select
        labelId="ProdServ"
        variant="outlined"
        value={finanza.concepto}
        fullWidth
        onChange={({ target }) => handleChange("concepto", target.value)}
      >
        {data.map((prod_serv) => (
          <MenuItem key={prod_serv.nombre} value={prod_serv.nombre}>
            {prod_serv.nombre}
          </MenuItem>
        ))}
      </Select>
      <CustomTextField
        label="Ingresos"
        placeholder="eg. 100"
        type="number"
        onChange={({ target }) => handleChange("ingresos", target.value)}
      />
      <CustomTextField
        label="Egresos"
        placeholder="eg. 300"
        type="number"
        onChange={({ target }) => handleChange("egresos", target.value)}
      />
      <CustomTextField
        label="Total"
        disabled
        placeholder="eg. 500"
        type="number"
        value={finanza.ingresos - finanza.egresos}
      />
      <CustomTextField
        label="Notas"
        type="text"
        placeholder="eg. Notas"
        onChange={({ target }) => handleChange("notas", target.value)}
      />
      <InputLabel id="tipoPago">Selecciona un tipo de pago</InputLabel>
      <Select
        labelId="tipoPago"
        variant="outlined"
        value={finanza.tipoPago}
        fullWidth
        onChange={({ target }) => handleChange("tipoPago", target.value)}
        sx={{ marginBottom: 2 }}
      >
        {tipoPago.map((tipo) => (
          <MenuItem key={tipo} value={tipo}>
            {tipo}
          </MenuItem>
        ))}
      </Select>
      <CustomTextField
        label="Nombre del paciente"
        type="text"
        placeholder="eg. Ricardo Arjona"
        onChange={({ target }) => handleChange("nombrePaciente", target.value)}
      />
      <CustomButton sx={{ mt: 3 }} onClick={() => handleSubmit()}>
        Agregar
      </CustomButton>
    </div>
  );
};

export default AgregarFinanza;
