import { useEffect, useState } from "react";
import { reqDoctorAPI } from "../API/reqDoctor";

export const GetData = (url) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const resp = await reqDoctorAPI.get(`${url}`);

    if (resp.data.length > 0) {
      //order data alphabetically

      setLoading(false);
      setData(
        resp.data.sort((a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        })
      );
    }
  };

  return {
    loading,
    getData,

    data,
  };
};

export const GetDesgloces = (url, url2) => {
  useEffect(() => {
    getDataGeneral();
    // getDataMensual();
  }, []);

  const [data, setData] = useState([]);
  const [dataMensual, setDataMensual] = useState([]);
  const [dataSemanal, setDataSemanal] = useState([]);

  const [loading, setLoading] = useState(true);

  const getDataGeneral = async () => {
    setLoading(true);
    const resp = await reqDoctorAPI.get(`${url}`);

    if (resp.data.length > 0) {
      setLoading(false);
      setData(resp.data);
    }
  };

  const getDataMensual = async () => {
    setLoading(true);
    const resp = await reqDoctorAPI.get(`${url2}`);

    if (resp.data.length > 0) {
      setLoading(false);
      setDataMensual(resp.data);
      // console.log(resp.data);
    }
  };

  // const getDataSemanal = async () => {
  //   setLoading(true);
  //   const resp = await reqDoctorAPI.get(`${url3}`);

  //   if (resp.data.length > 0) {
  //     setLoading(false);
  //     setDataSemanal(resp.data);
  //   }
  // };

  return {
    loading,
    data,
    dataMensual,
    dataSemanal,
  };
};
