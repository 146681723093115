import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import Login from "./Pages/Login/Login";
import Router from "./Route/Router";

ReactDOM.render(
  <Auth0Provider
    domain="dev-c905ari7.us.auth0.com"
    clientId="UQvXCTYmzW3H3VtCBziysLAksUL9y2F9"
    redirectUri={window.location.origin}
  >
    <Router />
  </Auth0Provider>,
  document.getElementById("root")
);
