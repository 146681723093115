import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import NoteCard from "../../components/NoteCard";
import Masonry from "react-masonry-css";
import { useNavigate } from "react-router-dom";
import "../../index.css";

const NotesHome = () => {
  const navigate = useNavigate();
  const [ButtonHover, setButtonHover] = React.useState(false);
  const [notas, setNotas] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/notes")
      .then((res) => setNotas(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    await axios.delete(`http://localhost:8000/notes/${id}`);

    const newNotas = notas.filter((nota) => nota.id !== id);
    setNotas(newNotas);
  };

  return (
    <>
      <Button
        variant={ButtonHover ? "contained" : "outlined"}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
        onClick={() => navigate("/notas/agregar")}
      >
        Nueva Nota
      </Button>
      <Container>
        <Masonry
          breakpointCols={3}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {notas.map((nota) => (
            <div key={nota.id}>
              <NoteCard prop={nota} handleDelete={handleDelete} />
            </div>
          ))}
        </Masonry>
      </Container>
    </>
  );
};

export default NotesHome;
