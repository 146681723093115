import * as React from "react";
import { makeStyles } from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Avatar } from "@mui/material";
import { menuItems } from "./Routes.jsx";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
const drawerWidth = 240;

const styless = makeStyles((theme) => {
  return {
    DrawerPaper: {
      width: drawerWidth,
      backgroundColor: "#f9f9f9",
    },
    root: {
      display: "flex",
    },
    page: {
      width: "100%",
      padding: theme.spacing(3),
    },
    Drawer: {
      width: drawerWidth,
      backgroundColor: "#f9f9f9",
    },
    title: {
      padding: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    Date: {
      flexGrow: 1,
      color: "white",
    },
    Avatar: {
      marginLeft: theme.spacing(2),
    },
  };
});
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 60,
  [theme.breakpoints.up("sm")]: {
    width: 60,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),

    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = ({ children, auth }) => {
  const navigate = useNavigate();
  const classes = styless();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getFecha = () => {
    // Creamos array con los meses del año
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    // Creamos array con los días de la semana
    const dias_semana = [
      "Domingo",
      "Lunes",
      "martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    // Creamos el objeto fecha instanciándolo con la clase Date
    const fecha = new Date();
    // Construimos el formato de salida
    return (
      dias_semana[fecha.getDay()] +
      ", " +
      fecha.getDate() +
      " de " +
      meses[fecha.getMonth()] +
      " de " +
      fecha.getUTCFullYear()
    );
  };
  // Aqui se agregan las rutas para el sidebar
  return (
    <div className={classes.root}>
      {/* app bar */}
      <AppBar>
        <Toolbar>
          <Typography className={classes.Date}> {getFecha()}</Typography>
          <Typography style={{ color: "white" }}>{auth.user.name}</Typography>
          <Avatar className={classes.Avatar} src={auth.user.picture} />
        </Toolbar>
      </AppBar>
      {/* side drawer */}

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader />

        <List style={{ marginTop: 100 }}>
          {menuItems.map((item) => (
            <ListItem
              key={item.titulo}
              button
              onClick={() => navigate(item.path)}
              style={location.pathname === item.path ? styles.active : null}
            >
              <ListItemIcon>{item.icono}</ListItemIcon>
              <ListItemText primary={item.titulo} sx={{ color: "black" }} />
            </ListItem>
          ))}
          <ListItem key={"Logout"} button onClick={() => auth.logout()}>
            <ListItemIcon>
              <LogoutIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Cerrar Sesion"} sx={{ color: "black" }} />
          </ListItem>
        </List>
      </Drawer>

      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
    </div>
  );
};

const styles = {
  active: {
    backgroundColor: "#9E9A9A",
  },
  AppBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#f9f9f9",
  },
};

export default Layout;
