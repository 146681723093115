import { reqDoctorAPI } from "../API/reqDoctor";

export const PutDataProducto = () => {
  const UpdateProducto = async (id, data) => {
    const resp = await reqDoctorAPI.put(`productos/actualizar/${id}`, {
      nombre: data.nombre,
      descripcion: data.descripcion,
      departamento: data.departamento,
      precio: data.precio,
      existencia: data.existencia,
    });
  };
  const UpdateProductoOnSell = async (id, data) => {
    const resp = await reqDoctorAPI.put(`productos/resta/${id}`, {
      nombre: data.concepto,
      existencia: 1,
    });
  };

  return { UpdateProducto, UpdateProductoOnSell };
};

export const PutDataServicio = () => {
  const UpdateServicio = async (id, data) => {
    const resp = await reqDoctorAPI.put(`servicios/actualizar/${id}`, {
      nombre: data.nombre,
      descripcion: data.descripcion,
      departamento: data.departamento,
      precio: data.precio,
    });
  };

  return { UpdateServicio };
};

export const PutDataFinanza = () => {
  const UpdateFinanza = async (id, data) => {
    const resp = await reqDoctorAPI.put(`finanzas/actualizar/${id}`, {
      departamento: data.departamento,
      fecha: data.fecha,
      concepto: data.concepto,
      ingresos: data.ingresos,
      egresos: data.egresos,
      total: data.total,
      notas: data.notas,
      tipoPago: data.tipoPago,
      remitente: data.remitente,
      nombrePaciente: data.nombrePaciente,
    });
  };

  return { UpdateFinanza };
};

// export const PutDataProducto = () => {
//   const UpdateProducto = async (id, data) => {
//     console.log(data);
//     const resp = await reqDoctorAPI.put(`productos/actualizar/${id}`, {
//       nombre: data.nombre,
//       descripcion: data.descripcion,
//       departamento: data.departamento,
//       precio: data.precio,
//       existencia: data.existencia,
//     });
//   };

//   return { UpdateProducto };
// };
