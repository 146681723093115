import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { PutDataServicio } from "../../Data/PutDataHook";

const CustomTextField = ({ label, ...props }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      margin="normal"
      fullWidth
      {...props}
    />
  );
};

const EditarServicio = ({ handleClose, data }) => {
  const { UpdateServicio } = PutDataServicio();
  const Departamentos = [
    "Condesa",
    "Clinica de Heridas",
    "Farmapronto",
    "NF Medica ACA",
    "Ortopedia Blanda",
    "Publicidad",
    "Rayos X",
    "Terapia Fisica",
    "Traumatologia y Ortopedia",
  ];
  const [servicio, setServicio] = useState({
    nombre: data.row.nombre,
    descripcion: data.row.descripcion,
    departamento: data.row.departamento,
    precio: data.row.precio,
  });

  const handleChange = (campo, value) => {
    setServicio({
      ...servicio,
      [campo]: value,
    });
  };

  const handleSubmit = (servicio) => {
    UpdateServicio(data.row.id, servicio);
    handleClose();
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 5 }}>
        Editar servicio
      </Typography>
      <CustomTextField
        label="Nombre del servicio"
        value={servicio.nombre}
        placeholder="eg. Vendaje"
        onChange={({ target }) => handleChange("nombre", target.value)}
      />
      <CustomTextField
        label="Descripción"
        value={servicio.descripcion}
        placeholder="eg. Servicio de vendaje"
        onChange={({ target }) => handleChange("descripcion", target.value)}
      />
      <CustomTextField
        label="Precio"
        value={servicio.precio}
        type="number"
        placeholder="eg. 100"
        onChange={({ target }) => handleChange("precio", target.value)}
      />

      <InputLabel id="departamentos">Selecciona un departamento</InputLabel>
      <Select
        labelId="departamentos"
        variant="outlined"
        value={servicio.departamento}
        fullWidth
        onChange={({ target }) => handleChange("departamento", target.value)}
      >
        {Departamentos.map((departamento) => (
          <MenuItem key={departamento} value={departamento}>
            {departamento}
          </MenuItem>
        ))}
      </Select>
      <CustomButton sx={{ mt: 3 }} onClick={() => handleSubmit(servicio)}>
        Editar
      </CustomButton>
    </div>
  );
};

export default EditarServicio;
