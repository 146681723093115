import React from "react";
import { Card, CardContent, Container, Typography } from "@mui/material";

const DesgloceCard = ({ title, data }) => {
  let dollarUSLocale = Intl.NumberFormat("en-US");
  return (
    <Card sx={{ mr: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 2 }}>{title}</Typography>
        <Typography sx={{ fontSize: 24 }} gutterBottom>
          $ {dollarUSLocale.format(data)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DesgloceCard;
