import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { PutDataProducto } from "../../Data/PutDataHook";

const CustomTextField = ({ label, ...props }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      margin="normal"
      fullWidth
      {...props}
    />
  );
};

const EditarProducto = ({ handleClose, data }) => {
  const { UpdateProducto } = PutDataProducto();
  const Departamentos = [
    "Condesa",
    "Clinica de Heridas",
    "Farmapronto",
    "NF Medica ACA",
    "Ortopedia Blanda",
    "Publicidad",
    "Rayos X",
    "Terapia Fisica",
    "Traumatologia y Ortopedia",
  ];
  const [producto, setProducto] = useState({
    nombre: data.row.nombre,
    descripcion: data.row.descripcion,
    departamento: data.row.departamento,
    precio: data.row.precio,
    existencia: data.row.existencia,
  });

  const handleChange = (campo, value) => {
    setProducto({
      ...producto,
      [campo]: value,
    });
  };

  const handleSubmit = (producto) => {
    UpdateProducto(data.row.id, producto);
    handleClose();
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 5 }}>
        Editar Producto
      </Typography>
      <CustomTextField
        label="Nombre del producto"
        value={producto.nombre}
        placeholder="eg. plantillas"
        onChange={({ target }) => handleChange("nombre", target.value)}
      />
      <CustomTextField
        label="Descripción"
        value={producto.descripcion}
        placeholder="eg. plantilla kids para niños"
        onChange={({ target }) => handleChange("descripcion", target.value)}
      />
      <CustomTextField
        label="Precio"
        value={producto.precio}
        type="number"
        placeholder="eg. 100"
        onChange={({ target }) => handleChange("precio", target.value)}
      />
      <CustomTextField
        label="existencia"
        type="number"
        value={producto.existencia}
        placeholder="eg. 3"
        onChange={({ target }) => handleChange("existencia", target.value)}
      />
      <InputLabel id="departamentos">Selecciona un departamento</InputLabel>
      <Select
        labelId="departamentos"
        variant="outlined"
        value={producto.departamento}
        fullWidth
        onChange={({ target }) => handleChange("departamento", target.value)}
      >
        {Departamentos.map((departamento) => (
          <MenuItem key={departamento} value={departamento}>
            {departamento}
          </MenuItem>
        ))}
      </Select>
      <CustomButton sx={{ mt: 3 }} onClick={() => handleSubmit(producto)}>
        Editar
      </CustomButton>
    </div>
  );
};

export default EditarProducto;
