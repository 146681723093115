import { reqDoctorAPI } from "../API/reqDoctor";

export const DeleteDataProducto = () => {
  const DeleteProducto = async (id) => {
    await reqDoctorAPI.delete(`productos/eliminar/${id}`);
  };

  return { DeleteProducto };
};

export const DeleteDataServicio = () => {
  const DeleteServicio = async (id) => {
    await reqDoctorAPI.delete(`servicios/eliminar/${id}`);
  };

  return { DeleteServicio };
};

export const DeleteDataFinanza = () => {
  const DeleteFinanza = async (id) => {
    await reqDoctorAPI.delete(`finanzas/eliminar/${id}`);
  };

  return { DeleteFinanza };
};
