import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomButton from "../../components/CustomButton";
import { Card, Container, Stack, Typography } from "@mui/material";
import LOGO from "../../images/logo.jpg";
const Login = ({ auth }) => {
  return (
    <Container
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        height: 600,
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      <Stack direction={"column"} alignContent="center" alignItems={"center"}>
        <img src={LOGO} style={{ width: 500, height: 500 }} />

        <CustomButton onClick={() => auth.loginWithRedirect()}>
          Iniciar Sesion
        </CustomButton>
      </Stack>{" "}
    </Container>
  );
};

export default Login;
