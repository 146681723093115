import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from "../App";
import Login from "../Pages/Login/Login";
const Router = () => {
  const auth = useAuth0();
  return (
    <>{auth.isAuthenticated ? <App auth={auth} /> : <Login auth={auth} />}</>
  );
};

export default Router;
