import * as React from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { GetData } from "../../Data/GetDataHook";
import CustomDatagrid from "../../components/CustomDatagrid";

const Usuarios = () => {
  const navigate = useNavigate();
  const { data, loading } = GetData("usuarios");

  const columns = [
    { field: "id", headerName: "ID", width: 2, headerAlign: "center" },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 300,
      headerAlign: "center",
    },
    {
      field: "usuario",
      headerName: "Usuario",
      width: 90,
    },
    {
      field: "rol",
      headerName: "Rol",
      type: "number",
      width: 2,
    },
  ];
  return (
    <>
      <Box mb={2}>
        <CustomButton onClick={() => navigate("/usuarios/agregar")}>
          Agregar Usuario
        </CustomButton>
      </Box>
      <CustomDatagrid rows={data} columns={columns} loading={loading} />
    </>
  );
};

export default Usuarios;
