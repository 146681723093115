import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomToolBar from "./CustomToolBar";
import { DataGrid, esES } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

function QuickSearchToolbar(props) {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
      <TextField
        fullWidth
        value={props.value}
        onChange={props.onChange}
        placeholder="Buscar..."
        InputProps={{
          //   startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      />
    </>
  );
}

const CustomDatagrid = (props) => {
  const [mainData, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(props.columns);

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  useEffect(() => {
    const newArr = props.rows;
    setData(newArr);
    setRows(newArr);
  }, [props.rows]);

  const getRecords = () => {
    const newArr = props.rows;
    setData(newArr);
    setRows(newArr);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = mainData.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  return (
    <>
      <Box style={{ height: 800, width: "100%" }}>
        <DataGrid
          sx={{ backgroundColor: "white" }}
          pageSize={(10, 25, 50, 100)}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{ Toolbar: QuickSearchToolbar }}
          rows={rows}
          columns={columns}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </Box>
    </>
  );
};

export default CustomDatagrid;
