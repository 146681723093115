import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomDatagrid from "../../components/CustomDatagrid";
import { GetData } from "../../Data/GetDataHook";

const Pacientes = () => {
  const navigate = useNavigate();
  const { data, loading } = GetData("pacientes");
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 2,
      headerAlign: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "telefono",
      headerName: "Telefono",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "fechaNacimiento",
      headerName: "Fecha de Nacimiento",
      type: "date",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "sexo",
      headerName: "Sexo",
      width: 6,
      headerAlign: "center",
    },
    {
      field: "ultimaVisita",
      headerName: "Ultima Visita",
      type: "date",
      width: 200,
    },
  ];

  return (
    <>
      <Box mb={2}>
        <CustomButton onClick={() => navigate("/pacientes/agregar")}>
          Agregar Paciente
        </CustomButton>
      </Box>

      <CustomDatagrid rows={data} columns={columns} loading={loading} />
    </>
  );
};

export default Pacientes;
