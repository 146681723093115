import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

const AgregarUsuario = () => {
  const navigate = useNavigate();
  const [ButtonHover, setButtonHover] = React.useState(false);
  const [alertVisible, setAlertVisible] = React.useState(false);

  const cleanTextFields = () => {
    document.getElementById("newUser").reset();
  };

  const [usuario, setUsuario] = React.useState([
    {
      nombre: "",
      apellidos: "",
      correo: "",
      usuario: "",
      contrasena: "",
      confirmacion: "",
      rol: "",
      telefono: "",
      username: "",
    },
  ]);
  const handleSubmit = (e) => {
    let user =
      usuario.nombre.substring(0, 3) + usuario.apellidos.substring(0, 3);
    user = user.toLowerCase();
    setUsuario({ ...usuario, username: user });
    e.preventDefault();
    setAlertVisible(true);
    cleanTextFields();
  };

  axios.post("http://localhost:3001/api/usuarios/agregar", usuario);

  return (
    <div>
      {alertVisible && (
        <Alert
          severity="success"
          onClose={() => setAlertVisible(false)}
          sx={{ mb: 3 }}
        >
          <AlertTitle>Usuario creado Exitosamente</AlertTitle>
          El usuario de la persona{" "}
          <strong>{usuario.nombre + " " + usuario.apellidos}</strong> ha sido
          creado exitosamente, su usuario es: —{" "}
          <strong>{usuario.username}</strong>
        </Alert>
      )}
      <CustomButton onClick={() => navigate("/usuarios")}>Atras</CustomButton>

      <Typography
        variant="h4"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        Agregar Usuario
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          m: 1,
          borderRadius: 1,
        }}
      >
        <form id="newUser">
          <Stack direction={"column"} spacing={2}>
            <TextField
              variant="outlined"
              label={"Nombre"}
              sx={{ width: 400 }}
              onChange={(e) =>
                setUsuario({ ...usuario, nombre: e.target.value })
              }
            />
            <TextField
              variant="outlined"
              label={"Apellidos"}
              onChange={(e) =>
                setUsuario({ ...usuario, apellidos: e.target.value })
              }
            />
            <TextField
              variant="outlined"
              label={"Correo"}
              onChange={(e) =>
                setUsuario({ ...usuario, correo: e.target.value })
              }
            />
            <TextField
              variant="outlined"
              label={"Teléfono"}
              onChange={(e) =>
                setUsuario({ ...usuario, telefono: e.target.value })
              }
            />
            <TextField
              type="password"
              variant="outlined"
              label={"Contraseña"}
              onChange={(e) =>
                setUsuario({ ...usuario, contrasena: e.target.value })
              }
            />
            <TextField
              type="password"
              variant="outlined"
              label={"Confirmar Contraseña"}
              onChange={(e) =>
                setUsuario({ ...usuario, confirmacion: e.target.value })
              }
            />
            <CustomButton type="submit" onClick={handleSubmit}>
              Agregar
            </CustomButton>
          </Stack>
        </form>
      </Box>
    </div>
  );
};

export default AgregarUsuario;
