import { useEffect, useState } from "react";
import { reqDoctorAPI } from "../API/reqDoctor";

export const PostDataProducto = () => {
  const postData = async (producto) => {
    const resp = await reqDoctorAPI.post("productos/nuevo", {
      nombre: producto.nombre,
      descripcion: producto.descripcion,
      precio: producto.precio,
      existencia: producto.existencia,
      departamento: producto.departamento,
    });
  };

  return {
    postData,
  };
};

export const PostDataServicio = () => {
  const postData = async (servicio) => {
    const resp = await reqDoctorAPI.post("servicios/nuevo", {
      nombre: servicio.nombre,
      descripcion: servicio.descripcion,
      precio: servicio.precio,
      departamento: servicio.departamento,
    });
  };

  return {
    postData,
  };
};

export const PostDataFinanza = () => {
  const [status, setStatus] = useState("");
  const postData = async (finanza) => {
    const resp = await reqDoctorAPI.post("finanzas/nuevo", {
      departamento: finanza.departamento,
      fecha: finanza.fecha,
      concepto: finanza.concepto,
      ingresos: finanza.ingresos,
      egresos: finanza.egresos,
      total: finanza.total,
      notas: finanza.notas,
      tipoPago: finanza.tipoPago,
      remitente: finanza.remitente,
      nombrePaciente: finanza.nombrePaciente,
    });

    setStatus(resp.status);
  };

  return {
    postData,
    status,
  };
};
