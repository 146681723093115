import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "../src/Route/PrivateRoute";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CreateNote from "./Pages/Notes/CreateNote";
import NotesHome from "./Pages/Notes/NotesHome";
import DashBoard from "./Pages/DashBoard/DashBoard";
import Finanzas from "./Pages/Finanzas/Finanzas";
import Productos from "./Pages/Productos/Productos";
import Pacientes from "./Pages/Pacientes/Pacientes";
import Usuarios from "./Pages/Usuarios/Usuarios";
import AgregarUsuario from "./Pages/Usuarios/AgregarUsuario";
import Servicios from "./Pages/Servicios/Servicios";
import Login from "./Pages/Login/Login";
import { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Layout from "./components/Layout";

const theme = createTheme({});

const App = ({ auth }) => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Fragment>
          <Layout auth={auth}>
            <Routes>
              <Route exact path="/" element={<DashBoard />} />
              <Route exact path="/productos" element={<Productos />} />
              <Route exact path="/productos/agregar" element={<Productos />} />
              <Route exact path="/servicios" element={<Servicios />} />
              <Route exact path="/finanzas" element={<Finanzas />} />
              <Route exact path="/pacientes" element={<Pacientes />} />
              <Route exact path="/usuarios" element={<Usuarios />} />
              <Route
                exact
                path="/usuarios/agregar"
                element={<AgregarUsuario />}
              />
              <Route exact path="/notas" element={<NotesHome />} />
              <Route exact path="/notas/agregar" element={<CreateNote />} />
            </Routes>
          </Layout>
        </Fragment>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
