import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { PostDataProducto } from "../../Data/PostDataHook";

const CustomTextField = ({ label, ...props }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      margin="normal"
      fullWidth
      {...props}
    />
  );
};

const AgregarProducto = ({ handleClose }) => {
  const { postData } = PostDataProducto();
  const Departamentos = [
    "Condesa",
    "Clinica de Heridas",
    "Farmapronto",
    "NF Medica ACA",
    "Ortopedia Blanda",
    "Publicidad",
    "Rayos X",
    "Terapia Fisica",
    "Traumatologia y Ortopedia",
  ];
  const [producto, setProducto] = useState({
    nombre: "",
    descripcion: "",
    departamento: "",
    precio: 100,
    existencia: 10,
  });

  const handleChange = (campo, value) => {
    setProducto({
      ...producto,
      [campo]: value,
    });
  };

  const handleSubmit = () => {
    postData(producto);
    handleClose();
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 5 }}>
        Agregar Producto
      </Typography>
      <CustomTextField
        label="Nombre del producto"
        placeholder="eg. plantillas"
        onChange={({ target }) => handleChange("nombre", target.value)}
      />
      <CustomTextField
        label="Descripción"
        placeholder="eg. plantilla kids para niños"
        onChange={({ target }) => handleChange("descripcion", target.value)}
      />
      <CustomTextField
        label="Precio"
        type="number"
        placeholder="eg. 100"
        onChange={({ target }) => handleChange("precio", target.value)}
      />
      <CustomTextField
        label="existencia"
        type="number"
        placeholder="eg. 3"
        onChange={({ target }) => handleChange("existencia", target.value)}
      />
      <InputLabel id="departamentos">Selecciona un departamento</InputLabel>
      <Select
        labelId="departamentos"
        variant="outlined"
        value={producto.departamento}
        fullWidth
        onChange={({ target }) => handleChange("departamento", target.value)}
      >
        {Departamentos.map((departamento) => (
          <MenuItem key={departamento} value={departamento}>
            {departamento}
          </MenuItem>
        ))}
      </Select>
      <CustomButton sx={{ mt: 3 }} onClick={() => handleSubmit(producto)}>
        Agregar
      </CustomButton>
    </div>
  );
};

export default AgregarProducto;
