import axios from "axios";
import React from "react";
import Chart from "react-apexcharts";
import { Card } from "@mui/material";

const ChartMensual = () => {
  const options2 = { style: "currency", currency: "USD" };
  const numberFormat2 = new Intl.NumberFormat("en-US", options2);
  const [datas, setData] = React.useState([]);

  const [options, setOptions] = React.useState({
    noData: {
      text: "Loading...",
    },
    chart: {
      id: "basic-line",
      background: "#ffffff",
    },
    stroke: {
      curve: "smooth",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      title: {
        text: "",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Finanzas mensuales",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#666",
        fontWeight: "regular",
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return numberFormat2.format(val);
        },
      },
    },
  });

  const getData = async () => {
    const resp = await axios.get(
      "https://doctorstotalclinic.onrender.com/api/finanzas/mensual"
    );
    setData([
      {
        name: "INGRESOS",
        data: resp?.data.map((item) => item.ingresos),
      },
      {
        name: "EGRESOS",
        data: resp?.data.map((item) => item.egresos),
      },
    ]);
    setOptions({
      xaxis: {
        categories: resp.data.map((finanza) => finanza.fecha),
      },
    });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Card sx={{ width: 500, height: 300 }}>
      <Chart
        options={options}
        series={datas}
        type="line"
        width={500}
        height={300}
      />
    </Card>
  );
};

export default ChartMensual;
