import { DeleteOutline, Note } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  test: {
    border: (prop) => {
      if (prop.categoria == "Finanzas") {
        return "1px solid red";
      }
    },
  },
});

const NoteCard = ({ prop, handleDelete }) => {
  const classes = useStyles(prop);
  return (
    <Card elevation={1} className={classes.test}>
      <CardHeader
        action={
          <IconButton onClick={() => handleDelete(prop.id)}>
            <DeleteOutline />
          </IconButton>
        }
        title={prop.titulo}
        subheader={prop.categoria}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          {prop.descripcion}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NoteCard;
