import React, { useEffect } from "react";
import ChartAnual from "./Components/ChartAnual";
import Masonry from "react-masonry-css";
import ChartDiaria from "./Components/ChartDiaria";
import ChartMensual from "./Components/ChartMensual";
import GridRecordatorio from "./Components/GridRecordatorio";
import { Stack } from "@mui/material";
import TipoPago from "./Components/TipoPago";
import BasicTabs from "./Tabs/BasicTab";
const DashBoard = () => {
  return (
    <div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <ChartAnual />
        <ChartMensual />
        <ChartDiaria />
      </Masonry>
      <BasicTabs />
      {/* <Stack direction={"row"}>
        <GridRecordatorio />
        <TipoPago />
      </Stack> */}
    </div>
  );
};

export default DashBoard;
