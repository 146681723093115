import * as React from "react";
import { Button } from "@mui/material";

const CustomButton = ({ children, ...props }) => {
  const [ButtonHover, setButtonHover] = React.useState(false);

  return (
    <Button
      {...props}
      variant={ButtonHover ? "contained" : "outlined"}
      onMouseEnter={() => setButtonHover(true)}
      onMouseLeave={() => setButtonHover(false)}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
