import { useState } from "react";
import { Button, FormControl, FormLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateNote = () => {
  const navigate = useNavigate();
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [tituloError, setTituloError] = useState(false);
  const [descripcionError, setDescripcionError] = useState(false);
  const [categoria, setCategoria] = useState("Finanzas");

  const handleSubmit = (e) => {
    e.preventDefault();
    setDescripcionError(false);
    setTituloError(false);

    if (titulo.length == "") {
      setTituloError(true);
    }

    if (descripcion.length == "") {
      setDescripcionError(true);
    }

    if (titulo && descripcion) {
      axios
        .post("http://localhost:8000/notes", {
          titulo: titulo,
          descripcion: descripcion,
          categoria: categoria,
        })
        .then(() => navigate("/"));
    }
  };

  return (
    <Container>
      <Typography
        color={"textSecondary"}
        variant="h6"
        component={"h2"}
        gutterBottom
      >
        Crear una nueva nota
      </Typography>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          onChange={(event) => setTitulo(event.target.value)}
          style={styles.field}
          label={"Titulo  de la nota"}
          variant="outlined"
          fullWidth
          required
          error={tituloError}
        />
        <TextField
          onChange={(event) => setDescripcion(event.target.value)}
          style={styles.field}
          label={"descripcion de la nota"}
          variant="outlined"
          fullWidth
          required
          multiline
          rows={4}
          error={descripcionError}
        />
        <FormControl style={styles.field}>
          <FormLabel>Categoria de la nota</FormLabel>
          <RadioGroup
            value={categoria}
            onChange={(event) => setCategoria(event.target.value)}
          >
            <FormControlLabel
              value={"Finanzas"}
              control={<Radio />}
              label={"Finanzas"}
            />

            <FormControlLabel
              value={"Recordatorios"}
              control={<Radio />}
              label={"Recordatorios"}
            />

            <FormControlLabel
              value={"Tareas"}
              control={<Radio />}
              label={"Tareas"}
            />

            <FormControlLabel
              value={"Trabajo"}
              control={<Radio />}
              label={"Trabajo"}
            />
          </RadioGroup>
        </FormControl>

        <Button
          onClick={() => console.log("you clicked me")}
          color="primary"
          type="submit"
          variant="contained"
          endIcon={<KeyboardArrowRight />}
        >
          Agregar
        </Button>
      </form>
    </Container>
  );
};

const styles = {
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
};

export default CreateNote;
